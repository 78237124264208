import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Header from "../components/header";
import Seo from "../components/seo";
import styled from "styled-components";
// import ThreeColumnLayout from '../components/threeColumnLayout';
import MEDIA from '../helpers/mediaTemplates';
import TwoColumnLayout from '../components/twoColumnLayout';


const VideoContainer = styled.div`
position: relative;
height: 0;
overflow: hidden;
padding-top: 50%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
margin: 2rem;
${MEDIA.PHONE`
  width: 0%;
  height: 0%;
  `};
`

const P5IFrame = styled.iframe`
position: absolute;
top: 0px;
left: 50%;
transform: translate(-50%, 0);
width: 1600px;
min-width: 1200px;
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const TextContainer = styled.div`
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
&::-webkit-scrollbar {
    display: none;
}
    width: 100%;
    max-width: 600px;

    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
`

const LOT = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "Trappist"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }
  return(
        <TwoColumnLayout
        Column1Size="66"
        Column1Content={
          <GridContainer>
          {images.map((image, index) => (
              <ImageGrid1x1 image={image} alt={data.allFile.edges[index].node.name} key={index}/>
          ))}
          </GridContainer>
        }
        Column2Size="33"
        Column2Content={
          <TextContainer>
            <Seo title="Life on TRAPPIST?" />
            <Header/>
            <h2>
              <i>Life on TRAPPIST?</i>
              <br /><br />

            {'Life on TRAPPIST? is an interactive data visualisation, purposed for museum display. It is displayed on a tablet and allows for touch interaction to visualise the similarities between our solar system and the Trappist Solar System.'}
            <br />        <br />
            {'The project uses the Java based coding environment, linked with data obtained from the NASA exoplanet archive.'}
            </h2>
          </TextContainer>
        }
        />
  )
}

export default LOT;
